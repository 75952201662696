<template>
  <div v-if="dataLoaded">
    <v-container class="pa-0">
      <!--app-view-toolbar-->
      <app-view-toolbar :title="headline" :buttons="toolbarButtons" @onButton="onToolbarButton"></app-view-toolbar>
    </v-container>
    <v-container class="py-2 px-6">
      <v-form
        ref="formEditArticleGroup"
        lazy-validation
      >
        <v-data-table
          :headers="tableData.headers"
          :items="tableData.items"
          :items-per-page="tableData.items.length"
          no-data-text="Inga artikelgrupper finns upplagda"
          hide-default-footer
          locale="sv-SE"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              @click.stop="deleteArticleGroup(item)"
              color="red"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <template v-slot:[`item.zag_int_discount_percent_default`]="{ item }">
            <v-text-field
              v-if="editMode"
              v-model="item.zag_int_discount_percent_default"
              :rules="[rules.required, rules.positiveInteger(item.zag_int_discount_percent_default, { min: 0, max: 100 })]"
              type="number"
              outlined
              dense
              suffix="%"
              hide-details
              class="number size-3"
            ></v-text-field>
            <div v-if="!editMode">{{ item.zag_int_discount_percent_default }} %</div>
          </template>
        </v-data-table>
      </v-form>
    </v-container>
    <v-dialog
      v-model="dialogAddArticleGroup"
      :fullscreen="useFullscreenDialog"
      :max-width="useFullscreenDialog ? 10000 : 600"
    >
      <v-card
        class="grey lighten-3"
      >
        <v-card-title>
          <span class="text-h6">Lägg till artikelgrupp</span>
          <v-spacer></v-spacer>
          <v-icon
            @click.stop="dialogAddArticleGroup = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text
          class="pb-0"
        >
          <v-form
            ref="formNewArticleGroup"
            lazy-validation
          >
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="pa-0"
                >
                  <v-text-field
                    v-model="newZag.zag_str_name"
                    label="Kod"
                    dense
                    outlined
                    maxlength="3"
                    background-color="white"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-text-field
                    v-model="newZag.zag_int_discount_percent_default"
                    label="Rabatt"
                    :rules="[rules.required, rules.positiveInteger(newZag.zag_int_discount_percent_default, { min: 0, max: 100 })]"
                    type="number"
                    outlined
                    dense
                    suffix="%"
                    hide-details
                    class="number size-3"
                    background-color="white"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="addArticleGroup"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--confirm for deleting customer-->
    <app-confirm :show="showDeleteArticleGroupConfirm" :title="deleteArticleGroupTitle" @onYes="executeDeleteArticleGroup" @onNo="showDeleteArticleGroupConfirm = false"></app-confirm>
  </div>
</template>

<script>
import { onMounted, ref, reactive, computed } from '@vue/composition-api'
import useCore from '../helpers/core'
import useUtils from '../helpers/utils'
import ViewToolbar from '../components/ViewToolbar.vue'
import useValidation from '../helpers/validation'
import { appBus } from '../main'
import Confirm from '../components/Confirm.vue'

export default {
  name: 'ZArticleGroups',
  components: {
    'app-view-toolbar': ViewToolbar,
    'app-confirm': Confirm,
  },
  setup() {
    const store = require('@/store/store').default;

    const useFullscreenDialog = store.getters.useFullscreenDialog;

    const { callApi } = useCore();
    const { deepCopyArray } = useUtils();
    const { rules } = useValidation();
    const editMode = ref(false);
    const isFormValid = ref(null);
    const onStartEditItems = ref();
    const showIgnoreEditConfirm = ref(false);
    const saveInProgress = ref(false);
    const dialogAddArticleGroup = ref(false);
    const formEditArticleGroup = ref(null);
    const formNewArticleGroup = ref(null);
    const showDeleteArticleGroupConfirm = ref(false);
    const deleteArticleGroupTitle = ref();
    const zagIdToDelete = ref();

    const headline = 'Artikelgrupper';
    const dataLoaded = ref(false);
    const tableData = reactive({
      headers: [],
      items: [],
    });

    const newZag = reactive({
      zag_str_name: '',
      zag_int_discount_percent_default: 0,
    });

    const onData = (referer, response) => {
      if (referer === "mounted") {
        tableData.headers = response.tableData.headers;
      }
      tableData.items = response.tableData.items;
      dataLoaded.value = true;
    };

    const openEditMode = () => {
      onStartEditItems.value = deepCopyArray(tableData.items);
      editMode.value = true;
    }

    const cancelEdit = () => {
      tableData.items = deepCopyArray(onStartEditItems.value);
      editMode.value = false;
    }
    
    const getEditedItems = () => {
      const editedItems = [];
      for (let i = 0; i < tableData.items.length; i++) {
        if (onStartEditItems.value[i].zag_int_discount_percent_default != tableData.items[i].zag_int_discount_percent_default) {
          editedItems.push(tableData.items[i]);
        }
      }
      return editedItems;
    }

    const setOverlay = (show = false, message = null) => {
      if (show) {
        saveInProgress.value = true;
        appBus.$emit('overlay', { message });
      } else {
        saveInProgress.value = false;
        appBus.$emit('overlay');
      }
    }

    const saveEdit = () => {
      const formIsValid = formEditArticleGroup.value.validate();
      if (!formIsValid) {
        return false;
      }
      editMode.value = false;
      const zags = getEditedItems();
      setOverlay(true, 'Sparar ändringar');
      callApi({
        method: 'patch',
        path: '/cms/fleettec/article-groups',
        data: {
          zags,
        }
      })
        .then((apiResponse) => {
          onData('saved', apiResponse);
          setOverlay();
        })
        .catch((err) => {
          setOverlay();
          console.log(err);
        });
    }
    
    const toolbarButtons = computed(() => {
      if (editMode.value) {
        return [
          {
            label: 'cancel',
            icon: 'cancel',
            name: 'Avbryt',
          },
          {
            label: 'save',
            icon: 'check',
            name: 'Spara',
          }
        ]
      }
      return [
        {
          label: 'edit',
          icon: 'pencil-outline',
          name: 'Redigera',
        },
        {
          label: 'new',
          icon: 'plus',
          name: 'Lägg till',
        }
      ];
    });

    const onToolbarButton = button => {
      switch (button.label) {
        case 'new':
          newZag.zag_str_name = '';
          newZag.zag_int_discount_percent_default = 0;
          dialogAddArticleGroup.value = true;
          break;
        case 'save':
          saveEdit();
          break;
        case 'cancel':
          cancelEdit();
          break;
        case 'edit':
          openEditMode();
          break;
      }
    }

    const addArticleGroup = () => {
      const formIsValid = formNewArticleGroup.value.validate();
      if (!formIsValid) {
        return false;
      }
      setOverlay(true, 'Sparar');
      callApi({
        method: 'put',
        path: '/cms/fleettec/article-groups',
        data: {
          zag: newZag,
        }
      })
        .then((apiResponse) => {
          onData('saved', apiResponse);
          dialogAddArticleGroup.value = false;
          setOverlay();
        })
        .catch((err) => {
          setOverlay();
          console.log(err);
        });
    }

    const deleteArticleGroup = (zag) => {
      deleteArticleGroupTitle.value = 'Ta bort ' + zag.zag_str_name + '?';
      showDeleteArticleGroupConfirm.value = true;
      zagIdToDelete.value = zag.zag_id;
    }

    const executeDeleteArticleGroup = () => {
      showDeleteArticleGroupConfirm.value = false;
      setOverlay(true, 'Tar bort');
      callApi({
        method: 'delete',
        path: '/cms/fleettec/article-groups/' + zagIdToDelete.value,
      })
        .then((apiResponse) => {
          onData('removed', apiResponse);
          setOverlay();
        })
        .catch((err) => {
          setOverlay();
          console.log(err);
        });
    }

    const doOnMounted = () => {
      callApi({
        method: 'get',
        path: '/cms/fleettec/article-groups',
      })
        .then((apiResponse) => {
          onData('mounted', apiResponse);
        })
        .catch((err) => {
          return err;
        });
    };

    onMounted(doOnMounted);

    return {
      headline,
      dataLoaded,
      tableData,
      onToolbarButton,
      toolbarButtons,
      deleteArticleGroup,
      rules,
      editMode,
      isFormValid,
      showIgnoreEditConfirm,
      saveEdit,
      cancelEdit,
      openEditMode,
      useFullscreenDialog,
      addArticleGroup,
      dialogAddArticleGroup,
      newZag,
      formEditArticleGroup,
      formNewArticleGroup,
      showDeleteArticleGroupConfirm,
      executeDeleteArticleGroup,
      deleteArticleGroupTitle,
    }
  },
};
</script>

<style>

</style>